import React from "react";

export default function KnowMore() {
    return (
        <div className="surface-100 p-5 text-700 text-center">
            <div className="text-blue-600 font-bold mb-3">
                <i className="pi"></i>O PODER DA PREVISÃO MATEMÁTICA
            </div>
            <div className="text-900 font-bold text-5xl mb-3">
                BigData & Machine Learning
            </div>
            <div className="text-700 text-2xl mb-5">
                Através da ciência de Dados, fornecemos análises preditivas avançadas, que abrem possibilidade de lucro inéditas no mercado
            </div>
        </div>
    );
}
