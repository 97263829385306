import React, { useCallback } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { AMC_API_URL, getToken } from "../../utils/api_controller";

const stripePromise = loadStripe(
    "pk_live_51O5ZA8Jp7YGMWabzV6OeGAJGpxSiY9FJQ060O1QzgRSqhfVGXFfTjoNJQbFuHnAudDSNh8D21wuOB5fr9nXfXYAq00qOKlsbZu"
);

export default function CheckOut({ purchaseDetails }) {
    localStorage.removeItem("purchaseDetails");
    const token = getToken();

    const fetchClientSecret = useCallback(async () => {
        const res = await fetch(`${AMC_API_URL}users/create_checkout_session/`, {
            headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json; charset=UTF-8",
            },
            method: "POST",
            body: JSON.stringify({
                alpha_code: purchaseDetails.alpha_code,
                ativo: purchaseDetails.ativo,
                tipo: purchaseDetails.tipo,
            }),
        });
        const data = await res.json();
        return data.session_client_secret;
    }, []);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
}
