import React from "react";
import { Chart } from "primereact/chart";

export default function AlphaChart({
    chartData,
    chartDates,
    chartType,
    chartLabel,
    chartId,
    chartHeight,
}) {
    const data = {
        labels: chartDates,
        datasets: [
            {
                label: chartLabel,
                data: chartData,
                // borderDash: [5, 5],
                tension: 0,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
    };

    return (
        <Chart
            style={{ height: chartHeight, marginBottom: "1em" }}
            id={chartId}
            type={chartType}
            data={data}
            options={options}
        />
    );
}
