import React, { useState } from "react";
import ShowJourneyWin from "./ShowJourneyWin";
import ShowJourneyWdo from "./ShowJourneyWdo";

export default function AlphaSwitcher() {
    const [selectedJourney, setSelectedJourney] = useState("win");

    const ativos = [
        { tipo: "win", nome: "Mini Índice" },
        { tipo: "wdo", nome: "Mini Dólar" },
    ];

    return (
        <div className="container">
            <div className="text-center mb-4">
                <div className="time-range grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer ">
                    {ativos.map((item, key) => (
                        <div
                            key={key}
                            className={
                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                (selectedJourney === item.tipo
                                    ? "text-gray-400 bg-bluegray-100"
                                    : "")
                            }
                            onClick={() => setSelectedJourney(item.tipo)}
                        >
                            {item.nome}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {selectedJourney === "win" ? <ShowJourneyWin /> : <ShowJourneyWdo />}
            </div>
        </div>
    );
}
