import React from 'react'

export default function FaqItem({faqItem}) {
  return (
    <>
        <div className="text-2xl font-bold">{(faqItem.pergunta).replaceAll("*", "")}</div>
        <div className='pb-3' dangerouslySetInnerHTML={{__html: (faqItem.resposta).replace("*", "<b>").replace("*", "</b>").replaceAll("*", "")}}>
        </div>
    </>
  )
}
