import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { amc_api } from "../../utils/api_controller";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import ReactGA from "react-ga4";

import "../../styles/pressy-style-toolkit.css";

import AlphaMetrics from "./AlphaMetrics";
import AlphaCharts from "./AlphaChart";

export default function AlphaDetails() {
    const navigate = useNavigate();
    const { alphaId, periodo: periodoUrl } = useParams(); // Captura do periodo a partir da URL
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(false);
    const [periodo, setPeriodo] = useState(periodoUrl || "12M"); // Usa o periodo da URL ou o default "12M"
    const [periodoLoading, setPeriodoLoading] = useState(false);
    const [granularity, setGranularity] = useState("month"); // Estado para a granularidade
    const [unit, setUnit] = useState("reais"); // Estado para a unidade (pontos ou reais)
    const chartHeights = "250px";

    function formatBRL(number) {
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    function formatWhatsAppText(text) {
        const formattedText = text
            .replace(/\*(.*?)\*/g, "<b>$1</b>") // Para negrito
            .replace(/_(.*?)_/g, "<i>$1</i>") // Para itálico
            .replace(/~(.*?)~/g, "<s>$1</s>") // Para riscado
            .replace(/`(.*?)`/g, "<code>$1</code>") // Para monoespaçado
            .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>'); // Para links

        return formattedText;
    }

    const periodosAvailable = [
        { periodo: "ALL", nome: "Desde 2022" },
        { periodo: "12M", nome: "Último 12 meses" },
        { periodo: "6M", nome: "Últimos 6 meses" },
    ];

    const handlePeriodo = (newPeriodo) => {
        if (newPeriodo !== periodo) {
            setPeriodoLoading(true);
            setPeriodo(newPeriodo);
        }
    };

    const handleGranularityChange = (newGranularity) => {
        if (newGranularity !== granularity) {
            setPeriodoLoading(true);
            setGranularity(newGranularity);
        }
    };

    const handleUnitChange = (newUnit) => {
        if (newUnit !== unit) {
            setPeriodoLoading(true);
            setUnit(newUnit);
        }
    };

    useEffect(() => {
        amc_api
            .get(
                `alphaportifolio/get_details/?alpha_code=${alphaId}&periodo=${periodo}&granularity=${granularity}`
            )
            .then((res) => {
                setData(res.data);
                ReactGA.send({
                    hitType: "pageview",
                    page: `/todos-alphas/alpha-details/${alphaId}/${granularity}`,
                    title: `Alpha: ${alphaId} - ${granularity}`,
                });
            })
            .catch((error) => setError(true))
            .finally(() => setLoading(false));
    }, [alphaId, periodo, granularity]);

    useEffect(() => {
        if (periodoLoading) {
            amc_api
                .get(
                    `alphaportifolio/get_details/?alpha_code=${alphaId}&periodo=${periodo}&granularity=${granularity}`
                )
                .then((res) => {
                    setData(res.data);
                    ReactGA.send({
                        hitType: "pageview",
                        page: `/todos-alphas/alpha-details/${alphaId}/${granularity}`,
                        title: `Alpha: ${alphaId} - ${granularity}`,
                    });
                })
                .catch((error) => setError(true))
                .finally(() => setPeriodoLoading(false));
        }
    }, [alphaId, periodo, granularity, periodoLoading]);

    const chooseAlpha = (alphaDetails) => {
        if (!data.can_buy) {
            return;
        }
        localStorage.setItem("purchaseDetails", JSON.stringify(alphaDetails));
        navigate(`/cart`);
    };

    return (
        <>
            <div className="container">
                {loading && (
                    <div className="flex">
                        <ProgressSpinner />
                    </div>
                )}
                {error && <>Erro ao carregar Alphas</>}
                {data && (
                    <>
                        <div className="header mt-5 mb-2 p-1 prsy-flex align-items-center justify-content-between">
                            <div className="identification">
                                <div className="text-3xl font-bold">
                                    {data.metrics.nome_tipo}
                                </div>
                                <div className="text-3xl font-bold">
                                    {data.metrics.tipo}
                                </div>
                                <div className="text-1xl">
                                    {data.metrics.alpha_description} (
                                    {data.metrics.alpha_code})
                                </div>
                                <div className="text-1xl">
                                    {formatBRL(parseFloat(data.metrics.price))}{" "}
                                    por mês
                                </div>
                            </div>
                            <div className="cta">
                                <div className="flex gap-3">
                                    <Button
                                        label={
                                            !data.can_buy
                                                ? "Indisponível"
                                                : formatBRL(
                                                      parseFloat(
                                                          data.metrics.price
                                                      )
                                                  ) + " por mês"
                                        }
                                        type="button"
                                        className={`p-button-raised w-full`}
                                        disabled={!data.can_buy}
                                        onClick={() => {
                                            chooseAlpha({
                                                alpha_code:
                                                    data.metrics.alpha_code,
                                                ativo: data.metrics.ativo,
                                                tipo: data.metrics.tipo,
                                            });
                                        }}
                                    />
                                    <Button
                                        label="Ver todas as opções"
                                        type="button"
                                        className="p-button-outlined w-full"
                                        onClick={() =>
                                            navigate("/todos-alphas")
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="header mt-5 mb-2 p-1 prsy-flex align-items-center justify-content-between">
                            <div className="identification">
                                <div className="text-1xl">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: formatWhatsAppText(
                                                data.metrics
                                                    .alpha_description_details
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="time-range grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer ">
                            {periodosAvailable.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        className={
                                            "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                            (periodo === item.periodo &&
                                                "text-gray-400 bg-bluegray-100")
                                        }
                                        onClick={() =>
                                            handlePeriodo(item.periodo)
                                        }
                                    >
                                        {item.nome}
                                    </div>
                                );
                            })}
                        </div>

                        {/* Estiliza os botões de granularidade como os períodos */}
                        <div className="granularity-options grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                            <div
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (granularity === "month" &&
                                        "text-gray-400 bg-bluegray-100")
                                }
                                onClick={() => handleGranularityChange("month")}
                            >
                                Por Mês
                            </div>
                            <div
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (granularity === "day" &&
                                        "text-gray-400 bg-bluegray-100")
                                }
                                onClick={() => handleGranularityChange("day")}
                            >
                                Por Dia
                            </div>
                        </div>

                        {/* Botões "Em Pontos" e "Em Reais" */}
                        <div className="unit-options grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                            <div
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (unit === "reais" &&
                                        "text-gray-400 bg-bluegray-100")
                                }
                                onClick={() => handleUnitChange("reais")}
                            >
                                Em Reais
                            </div>
                            <div
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (unit === "pontos" &&
                                        "text-gray-400 bg-bluegray-100")
                                }
                                onClick={() => handleUnitChange("pontos")}
                            >
                                Em Pontos
                            </div>
                        </div>

                        <div className="br-1 mt-3 mb-3">
                            {periodoLoading ? (
                                <Skeleton width="100%" height="65px" />
                            ) : (
                                <AlphaMetrics
                                    data={data.metrics}
                                    unit={unit} // Passar a unidade atual para o componente AlphaMetrics
                                />
                            )}
                        </div>
                        <div className="flex flex-column w-full gap-3 mb-3">
                            {periodoLoading ? (
                                <>
                                    <Skeleton
                                        width="100%"
                                        height={chartHeights}
                                    />
                                    <Skeleton
                                        width="100%"
                                        height={chartHeights}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="chart1">
                                        <AlphaCharts
                                            chartData={
                                                unit === "reais"
                                                    ? data.returns
                                                          .soma_acumulada_em_reais_mercado
                                                    : data.returns
                                                          .soma_acumulada_em_pontos
                                            }
                                            chartDates={data.returns.datas}
                                            chartType={"line"}
                                            chartLabel={
                                                unit === "reais"
                                                    ? "Acumulado em R$"
                                                    : "Acumulado em Pontos"
                                            }
                                            chartId={data.metrics.alpha_code}
                                            chartHeight={chartHeights}
                                        />
                                    </div>
                                    <div className="chart2">
                                        <AlphaCharts
                                            chartData={
                                                unit === "reais"
                                                    ? data.returns
                                                          .retornos_em_reais_mercado
                                                    : data.returns
                                                          .retornos_em_pontos
                                            }
                                            chartDates={data.returns.datas}
                                            chartType={"bar"}
                                            chartLabel={
                                                unit === "reais"
                                                    ? "Retorno em R$"
                                                    : "Retorno em Pontos"
                                            }
                                            chartId={data.metrics.alpha_code}
                                            chartHeight={chartHeights}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
