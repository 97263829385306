import React, { useState, useEffect } from "react";
import { amc_api, clearSession } from "../../utils/api_controller";

import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";

import NoAlpha from "./NoAlpha";
import ShowJourney from "./ShowJourneyAll";

export default function MyAccount() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");

    useEffect(() => {
        amc_api
            .get("alphaportifolio/get_jornadas_trader/")
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(() => setLoading(false));
    }, []);

    return (
        <div className="container">
            {loading && <ProgressSpinner />}
            {error && <>Erro ao carregar página</>}
            {data && (
                <>
                    <div className="container">
                        <div className="text-3xl pt-3 font-bold">
                            Minha Conta
                        </div>
                        <p>
                            <Button
                                label="Sair"
                                type="button"
                                className="mr-3 bg-bluegray-500"
                                onClick={clearSession}
                            />
                        </p>
                        <ShowJourney/>
                    </div>
                </>
            )}
        </div>
    );
}
