import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div className="privacy-policy p-6">
            <h1 className="text-3xl font-bold mb-4">Política de Privacidade</h1>
            <p className="mb-4">
                A sua privacidade é importante para nós. É política do <strong>Alpha Monkey Club (AMC)</strong> respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site <strong>Alpha Monkey Club</strong>, e outros sites que possuímos e operamos.
            </p>
            <p className="mb-4">
                Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
            </p>
            <p className="mb-4">
                Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
            </p>
            <p className="mb-4">
                Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.
            </p>
            <p className="mb-4">
                Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
            </p>
            <p className="mb-4">
                O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-4">Compromisso do Usuário</h2>
            <p className="mb-4">
                O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o <strong>Alpha Monkey Club (AMC)</strong> oferece no site e com caráter enunciativo, mas não limitativo:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>Não se envolver em atividades que sejam ilegais ou contrárias à boa fé e à ordem pública;</li>
                <li>Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, casas de apostas (ex.: <em>betting</em>), jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
                <li>Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do <strong>Alpha Monkey Club (AMC)</strong>, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-4">Mais Informações</h2>
            <p className="mb-4">
                Esperamos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.
            </p>
            <p className="mb-4">
                Esta política é efetiva a partir de <strong>15 de setembro de 2024</strong>.
            </p>
            <p className="mt-6">
                <strong>Entre em contato conosco:</strong> <br />
                Email: support@alphamonkeyclub.com <br />
                Endereço: 34 Nevada Place, St. Albert, Alberta.
            </p>
        </div>
    );
}
