import React, { useState, useEffect } from "react";
import WhatsappIcon from "../assets/WhatsApp_icon.png";

export default function Whatsapp() {
    const [isVisible, setIsVisible] = useState(true);

    // Função para detectar rolagem
    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 100) {
            setIsVisible(false); // Esconde o botão quando rola para baixo
        } else {
            setIsVisible(true); // Mostra o botão quando está no topo
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        isVisible && (
            <div
                style={{
                    position: "fixed",
                    bottom: 50,
                    right: 50,
                    backgroundColor: "#ededed",
                    paddingLeft: 15,
                    paddingRight: 15,
                    borderRadius: 20,
                    zIndex: 99,
                    transition: "opacity 0.3s ease",
                }}
            >
                <a
                    href="https://api.whatsapp.com/send/?phone=15795008204&text=Oi&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex align-items-center no-underline font-bold"
                    style={{
                        color: "#2563eb",
                    }}
                >
                    Fale Conosco
                    <img
                        style={{ width: 50 }}
                        src={WhatsappIcon}
                        alt="Fale conosco"
                    />
                </a>
            </div>
        )
    );
}
