import React, { useState, useEffect } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { amc_api } from "../../utils/api_controller";
import AlphaBlock from "../listAllAlphas/AlphaBlock";
import { Paginator } from "primereact/paginator";
import ReactGA from "react-ga4";


export default function TodosAlphas() {
    ReactGA.send({
        hitType: "pageview",
        page: "/todos-alphas",
        title: "Todos os Alphas",
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [maxPages, setMaxPages] = useState(1);
    const [tipoAlphaPlan, setTipoAlphaPlan] = useState("all");
    const [ativo, setAtivo] = useState("all");
    const [brokerages, setBrokerages] = useState([]); 
    const [selectedBrokerage, setSelectedBrokerage] = useState(null); 
    const [ordenacao, setOrdenacao] = useState('acuracia'); // State para o parâmetro de ordenação

    const tiposAlphaPlan = [
        { tipo: "all", nome: "Todos" },
        { tipo: "standard", nome: "Standard" },
        { tipo: "dynamic", nome: "Dynamic" },
        { tipo: "automated", nome: "Automated" },
    ];

    const ativos = [
        { tipo: "all", nome: "Todos" },
        { tipo: "win", nome: "Mini Índice" },
        { tipo: "wdo", nome: "Mini Dólar" },
    ];

    const ordenacoes = [
        { tipo: 'acuracia', nome: 'Acurácia' },
        { tipo: 'payout_diario_em_reais', nome: 'Payoff' },
        { tipo: 'drawdown_maximo_em_reais', nome: 'Drawdown' },
    ];

    useEffect(() => {
        let url = `alphaportifolio/get_alphas_plans/?pagina=${currentPage + 1}&quantidade=8&tipo_alpha_plan=${tipoAlphaPlan}&ativo=${ativo}&ordenacao=${ordenacao}`;
        if (selectedBrokerage) {
            url += `&brokerage_id=${selectedBrokerage}`;
        }

        amc_api
            .get(url)
            .then((res) => {
                setData(res.data);
                getPagesQtd(
                    res.data.total_produtos,
                    res.data.quantidade_por_pagina
                );
            })
            .catch((error) => setError(true))
            .finally(() => setLoading(false));
    }, [currentPage, tipoAlphaPlan, ativo, selectedBrokerage, ordenacao]);

    function getPagesQtd(totalProducts, qtdPerPage) {
        let pages = totalProducts / qtdPerPage;

        if (pages > parseInt(pages)) {
            setMaxPages(parseInt(pages) + 1);
        }
    }

    const onPageChange = (event) => {
        setLoading(true);
        setCurrentPage(event.page);
    };

    const handleTipoAlphaPlan = (newTipo) => {
        if (newTipo !== tipoAlphaPlan) {
            setLoading(true);
            setTipoAlphaPlan(newTipo);
            setCurrentPage(0); // Resetar para a primeira página

            if (newTipo === 'automated') {
                amc_api.get('alphaportifolio/get_brokerages/')
                    .then((res) => {
                        setBrokerages(res.data.brokerages);
                        setSelectedBrokerage(null);  
                    })
                    .catch((error) => console.error(error));
            } else {
                setBrokerages([]); 
                setSelectedBrokerage(null);
            }
        }
    };

    const handleAtivo = (newAtivo) => {
        if (newAtivo !== ativo) {
            setLoading(true);
            setAtivo(newAtivo);
            setCurrentPage(0); // Resetar para a primeira página
        }
    };

    const handleOrdenacao = (newOrdenacao) => {
        if (newOrdenacao !== ordenacao) {
            setLoading(true);
            setOrdenacao(newOrdenacao);
            setCurrentPage(0); // Resetar para a primeira página
        }
    };

    const handleBrokerageSelection = (brokerageId) => {
        setLoading(true);
        setSelectedBrokerage(brokerageId);
        setCurrentPage(0); // Resetar para a primeira página
    };

    return (
        <>
            <div className="flex p-6 text-center justify-content-center">
                {loading ? (
                    <ProgressSpinner />
                ) : (
                    <>
                        {error && <>Erro ao carregar Alphas</>}
                        {data && (
                            <div className="alpha-details">
                                <div className="text-center text-3xl font-bold">
                                    Escolha seu Alpha e comece agora!
                                </div>

                                <div className="time-range grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer ">
                                    {tiposAlphaPlan.map((item, key) => (
                                        <div
                                            key={key}
                                            className={
                                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                                (tipoAlphaPlan === item.tipo &&
                                                    "text-gray-400 bg-bluegray-100")
                                            }
                                            onClick={() => handleTipoAlphaPlan(item.tipo)}
                                        >
                                            {item.nome}
                                        </div>
                                    ))}
                                </div>

                                <div className="time-range grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer ">
                                    {ativos.map((item, key) => (
                                        <div
                                            key={key}
                                            className={
                                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                                (ativo === item.tipo &&
                                                    "text-gray-400 bg-bluegray-100")
                                            }
                                            onClick={() => handleAtivo(item.tipo)}
                                        >
                                            {item.nome}
                                        </div>
                                    ))}
                                </div>

                                {tipoAlphaPlan === 'automated' && brokerages.length > 0 && (
                                    <div className="brokerages grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                                        <div
                                            className={
                                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                                (!selectedBrokerage &&
                                                    "text-gray-400 bg-bluegray-100")
                                            }
                                            onClick={() => handleBrokerageSelection(null)}
                                        >
                                            Todos
                                        </div>
                                        {brokerages.map((brokerage, key) => (
                                            <div
                                                key={key}
                                                className={
                                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                                    (selectedBrokerage === brokerage.id &&
                                                        "text-gray-400 bg-bluegray-100")
                                                }
                                                onClick={() =>
                                                    handleBrokerageSelection(brokerage.id)
                                                }
                                            >
                                                {brokerage.name}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Botões de ordenação */}
                                <div className="ordenacao grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                                    {ordenacoes.map((item, key) => (
                                        <div
                                            key={key}
                                            className={
                                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                                (ordenacao === item.tipo &&
                                                    "text-gray-400 bg-bluegray-100")
                                            }
                                            onClick={() => handleOrdenacao(item.tipo)}
                                        >
                                            {item.nome}
                                        </div>
                                    ))}
                                </div>

                                <div className="flex justify-content-center">
                                    <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                                        {data.produtos.map((produto, key) => (
                                            <AlphaBlock key={key} data={produto} />
                                        ))}
                                    </div>
                                </div>
                                <div className="card">
                                    <Paginator
                                        first={currentPage}
                                        rows={1}
                                        totalRecords={maxPages}
                                        onPageChange={onPageChange}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
