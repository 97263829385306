import React, { useState, useEffect } from "react";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import FaqSubCategory from "./FaqSubcategory";

export default function FaqCategory({ faqCategory }) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");

    const getSubCategoriesUri =
        "https://tzv1a26gyc.execute-api.us-east-1.amazonaws.com/prod/faq/?action=get_subcategories&category=" +
        faqCategory.index;

    useEffect(() => {
        axios
            .get(getSubCategoriesUri)
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <div>
                {loading && <ProgressSpinner />}
                {error && <>Erro ao carregar categoria de FAQ.</>}
                {data && (
                    <div className="pb-3">
                        <div className="text-4xl font-bold">
                        {faqCategory.index} - {faqCategory.name}
                        </div>
                        {data.map((subCategory, key) => (
                            <FaqSubCategory
                                key={key}
                                faqCategory={faqCategory.index}
                                faqSubCategory={subCategory}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
