import { Routes, Route } from "react-router-dom";

import { menuLinks } from "./MenuLinks";

export default function AppRoutes() {
    return (
        <Routes>
            {menuLinks.map((route, key) => {
                return (
                    <Route
                        key={key}
                        path={route.path}
                        element={route.component}
                    />
                );
            })}
        </Routes>
    );
}
