// TermsOfUse.js
import React from 'react';

export default function TermsOfUse() {
    return (
        <div className="terms-of-use">
            <h1>Termos de Uso</h1>
            <p>{/* Insira o texto dos seus termos de uso aqui */}</p>
        </div>
    );
}
