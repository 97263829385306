import React, { useState, useEffect } from "react";

import { ProgressSpinner } from "primereact/progressspinner";
import { amc_api } from "../../utils/api_controller";

import AlphaChart from "./AlphaChart";
import AlphaMetrics from "./AlphaMetrics";

export default function AlphaBlockDetails({ alphaId, stripeId }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(false);

    useEffect(() => {
        amc_api
            .get(
                `alphaportifolio/get_details/?alpha_code=${alphaId}&granularity=month`
            )
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(() => setLoading(false));
    }, [alphaId]);

    return (
        <>
            {loading && <ProgressSpinner />}
            {error && <>Erro ao carregar detalhes.</>}
            {data && (
                <>
                    <AlphaChart
                        chartData={data.returns.soma_acumulada_em_reais_mercado}
                        chartDates={data.returns.datas}
                        chartType={"line"}
                        chartLabel={"Acumulado em R$"}
                        chartId={alphaId}
                    />
                    <AlphaMetrics data={data.metrics} noDrawdown={true} unit='reais'/>
                </>
            )}
        </>
    );
}
