import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import AlphaMetrics from "../listAllAlphas/AlphaMetrics";
import AlphaChart from "../listAllAlphas/AlphaChart";
import { amc_api } from "../../utils/api_controller"; // Certifique-se de importar corretamente seu controlador de API

export default function AlphaJourneyWdo({ data }) {
    const [helpVisible, setHelpVisible] = useState(null);
    const [journeyData, setJourneyData] = useState(data);
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const typingSpeed = 5; // Velocidade de digitação (ms por caractere)
    const scrollRef = useRef(null); // Ref para o div que conterá o texto

    const chartHeights = "250px";

    // Define se a previsão é positiva ou negativa
    const isPositive = journeyData.predict_details.predict_details.previsao !== "Baixa";
    const journeyId = journeyData.metrics.journey_id;
    const journeyType = journeyData.metrics.codigo_ativo.toLowerCase() + '_' + journeyData.metrics.tipo_plano.toLowerCase(); // Converte 'ativo' e 'tipo' para minúsculas e os concatena
 
    // Verifica se a jornada está arquivada com base em 'data_fim'
    const isArchived = !!journeyData.metrics.data_fim;

    function formatBRL(number) {
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    const formatTextForDisplay = (text) => {
        if (!text) return '';

        // Negrito *EXCELENTE*
        text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

        // Itálico _italico_
        text = text.replace(/_(.*?)_/g, '<em>$1</em>');

        // Sublinhado ~sublinhado~
        text = text.replace(/~(.*?)~/g, '<u>$1</u>');

        // Quebra de linha (\n ou \r)
        text = text.replace(/(\r\n|\n|\r)/g, '<br/>');

        return text;
    };

    const toggleHelp = async (section) => {
        setHelpVisible(helpVisible === section ? null : section);

        // Verifica se o campo de observação foi lido ou não
        const isRead = journeyData.metrics[`${section}_observacao_read`];

        if (!isRead) {
            try {
                await amc_api.post(`/alphaportifolio/journey/${journeyType}/${journeyId}/mark_observation/${section}/`);

                // Atualiza o estado após marcar como lido
                setJourneyData((prevState) => ({
                    ...prevState,
                    metrics: {
                        ...prevState.metrics,
                        [`${section}_observacao_read`]: true,
                    }
                }));
            } catch (error) {
                console.error("Erro ao atualizar a observação:", error);
            }
        }
    };

    const openModal = (section) => {
        const detailedText = formatTextForDisplay(journeyData.metrics[`${section}_detalhe_observacao`]);
        setModalContent(detailedText);
        setDisplayedText(''); // Reseta o texto mostrado
        setCurrentIndex(0); // Reseta o índice atual para o efeito de digitação
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (isModalOpen && currentIndex < modalContent.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + modalContent[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight; // Rola para o final
                }
            }, typingSpeed);

            return () => clearTimeout(timeout);
        }
    }, [isModalOpen, currentIndex, modalContent]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
        setDisplayedText('');
        setCurrentIndex(0);
    };

    return (
        <div className="alphaDetails">
            {/* Modal Component */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeModal}>&times;</button>
                        </div>
                        <div
                            ref={scrollRef} // Ref para o div do texto
                            className="modal-body"
                            style={{ overflowY: 'auto', maxHeight: '400px' }}
                            dangerouslySetInnerHTML={{ __html: displayedText }}
                        />
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                label="Fechar"
                                type="button"
                                className="p-button-raised"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="header mt-5 mb-2 flex align-items-center justify-content-between">
                <div className="identification">
                    <div className="text-4xl font-bold">
                        {`${journeyData.metrics.nome_ativo} - ${journeyData.metrics.tipo_plano}`}
                    </div>
                    <div className="text-2xl">
                        {journeyData.metrics.alpha_description} ({journeyData.metrics.alpha_code})
                    </div>
                </div>

                {/* Renderiza a seção de previsão apenas se a jornada NÃO estiver arquivada */}
                {!isArchived && (
                    <div className="cta">
                        <div className="flex gap-3">
                            <div className={`flex flex-column text-center p-1 pl-4 pr-4 br-1 ` + (isPositive ? "bg-blue-100" : "bg-orange-100")}>
                                <div className="previsao font-bold">
                                    Previsão para o próximo dia útil
                                </div>
                                <div className="previsao font-bold text-2xl">
                                    {journeyData.predict_details.predict_details.previsao}
                                </div>
                                <div className="contratos">
                                    {journeyData.predict_details.predict_details.quant_contratos + (journeyData.predict_details.predict_details.quant_contratos > 1 ? " contratos" : " contrato")}
                                </div>
                                <div className="contratos">
                                    Atualizado em: {journeyData.predict_details.predict_details.last_date_mini}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="br-1 mt-3 mb-3">
                <div className="shadow-1 br-1 p-2 mt-2 mb-2">
                    <div className="prsy-grid prsy-col4 justify-content-between text-center">
                        <div className="flex flex-column border-right-1 border-200">
                            <div className="question-container">
                                <b>Acurácia</b>
                                <span
                                    className={`question-icon ${journeyData.metrics.acuracia_observacao_read ? "bg-gray" : "bg-yellow"}`}
                                    onClick={() => toggleHelp('acuracia')}
                                >?</span>
                                {helpVisible === 'acuracia' && (
                                    <div className="help-box">
                                        <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(journeyData.metrics.acuracia_observacao) }} />
                                        <br />
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            openModal('acuracia');
                                        }}>Saiba mais</a>
                                    </div>
                                )}
                            </div>
                            <span>{journeyData.metrics.acuracia.toFixed(2)}%</span>
                        </div>

                        <div className="flex flex-column border-right-1 border-200">
                            <div className="question-container">
                                <b>Drawdown máximo</b>
                                <span
                                    className={`question-icon ${journeyData.metrics.drawdown_observacao_read ? "bg-gray" : "bg-yellow"}`}
                                    onClick={() => toggleHelp('drawdown')}
                                >?</span>
                                {helpVisible === 'drawdown' && (
                                    <div className="help-box">
                                        <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(journeyData.metrics.drawdown_observacao) }} />
                                        <br />
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            openModal('drawdown');
                                        }}>Saiba mais</a>
                                    </div>
                                )}
                            </div>
                            <span>{formatBRL(journeyData.metrics.drawdown_maximo_em_reais)}</span>
                        </div>

                        <div className="flex flex-column border-right-1 border-200">
                            <div className="question-container">
                                <b>Payoff</b>
                                <span
                                    className={`question-icon ${journeyData.metrics.payout_observacao_read ? "bg-gray" : "bg-yellow"}`}
                                    onClick={() => toggleHelp('payout')}
                                >?</span>
                                {helpVisible === 'payout' && (
                                    <div className="help-box">
                                        <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(journeyData.metrics.payout_observacao) }} />
                                        <br />
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            openModal('payout');
                                        }}>Saiba mais</a>
                                    </div>
                                )}
                            </div>
                            <span>{formatBRL(journeyData.metrics.payout_diario_em_reais)}</span>
                        </div>

                        <div className="flex flex-column">
                            <div className="question-container">
                                <b>Total</b>
                                <span
                                    className={`question-icon ${journeyData.metrics.acumulo_total_observacao_read ? "bg-gray" : "bg-yellow"}`}
                                    onClick={() => toggleHelp('acumulo_total')}
                                >?</span>
                                {helpVisible === 'acumulo_total' && (
                                    <div className="help-box">
                                        <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(journeyData.metrics.acumulo_total_observacao) }} />
                                        <br />
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            openModal('acumulo_total');
                                        }}>Saiba mais</a>
                                    </div>
                                )}
                            </div>
                            <span>{formatBRL(journeyData.metrics.total_em_reais)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-column w-full gap-3 mb-3">
                <div className="chart1">
                    <AlphaChart
                        chartData={journeyData.returns.soma_acumulada_em_reais_mercado}
                        chartDates={journeyData.returns.datas}
                        chartType={"line"}
                        chartLabel={"Acumulado em R$"}
                        chartId={journeyData.metrics.alpha_code}
                        chartHeight={chartHeights}
                    />
                </div>
                <div className="chart2">
                    <AlphaChart
                        chartData={journeyData.returns.retornos_em_reais_mercado}
                        chartDates={journeyData.returns.datas}
                        chartType={"bar"}
                        chartLabel={"Retorno em R$"}
                        chartId={journeyData.metrics.alpha_code}
                        chartHeight={chartHeights}
                    />
                </div>
            </div>
        </div>
    );
}
