import axios from "axios";

export const AMC_API_URL = "https://api.alphamonkeyclub.com/";
// export const AMC_API_URL = "https://77a7-2804-1b3-adc1-e957-7692-47ba-cc53-6469.ngrok-free.app/"

// API - Connection Controller
export const amc_api = axios.create({
    // baseURL: "https://api.alphamonkeyclub.com/",
    baseURL: AMC_API_URL,
    headers: {
        Accept: "application/json",
        "Content-type": "application/json",
    },
});

// API - Authorization
amc_api.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
});

// TOKEN - Retrieve Token
export function getToken() {
    return localStorage.getItem("token")
        ? localStorage.getItem("token")
        : false;
}

// TOKEN - Remove Token
export function clearSession() {
    localStorage.removeItem("token");
    window.location.href = "";
}

// USER - Check access
export function checkAccess() {
    const token = getToken();

    if (token) {
        return amc_api
            .get("/users/logged_user/")
            .then(() => true)
            .catch(() => {
                clearSession();
                return false;
            });
    } else {
        return false
    }
}
