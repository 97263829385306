import React from "react";
import { HashRouter } from "react-router-dom";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import AppRoutes from "./navigation/AppRoutes";

import "primeflex/themes/primeone-light.css";
import Whatsapp from "./components/Whatsapp";

import ReactGA from "react-ga4";
import CookieConsent from "react-cookie-consent"; // Importação adicionada

export default function App() {
    ReactGA.initialize("G-LWLJGXV8YJ");

    return (
        <HashRouter>
            <Whatsapp />
            <Header />
            <AppRoutes />
            <Footer />

            {/* Banner de Consentimento de Cookies */}
            <CookieConsent
                location="bottom"
                buttonText="Aceitar"
                declineButtonText="Recusar"
                enableDeclineButton
                onAccept={() => {
                    // Inicializar scripts após consentimento
                    ReactGA.initialize("G-LWLJGXV8YJ"); // Exemplo de inicialização do GA
                }}
                onDecline={() => {
                    // Ações caso o usuário recuse os cookies
                    // Por exemplo, desabilitar o GA ou outros scripts
                }}
                cookieName="amcCookieConsent"
                style={{ background: "#000000" }}
                buttonStyle={{
                    color: "#ffffff",
                    background: "#f1d600",
                    fontSize: "14px",
                }}
                declineButtonStyle={{
                    color: "#ffffff",
                    background: "#c00",
                    fontSize: "14px",
                }}
                expires={150}
            >
                Utilizamos cookies para melhorar sua experiência em nosso site.{" "}
                <a href="/#/politica-de-privacidade" style={{ color: "#f1d600" }}>
                    Leia nossa Política de Privacidade
                </a>
                .
            </CookieConsent>
        </HashRouter>
    );
}
