import React, { useState, useEffect } from "react";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import FaqItem from "./FaqItem";

export default function FaqSubCategory({ faqCategory, faqSubCategory }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");

    const uri = `https://tzv1a26gyc.execute-api.us-east-1.amazonaws.com/prod/faq/?action=get_questions&category=${faqCategory}&subcategory=${faqSubCategory.index}`

    useEffect(() => {
        axios
            .get(uri)
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <div>
                {loading && <ProgressSpinner />}
                {error && <>Erro ao carregar sub-categoria de FAQ.</>}
                {data && (
                    <div className="pb-3">
                        <div className="text-3xl font-bold pt-1">{faqCategory}.{faqSubCategory.index} - {faqSubCategory.name}</div>
                        <div>
                            { data.map((item, key) => <FaqItem key={key} faqItem={item} />) }
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
