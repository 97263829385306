import React, { useState, useEffect } from "react";
import { amc_api } from "../../utils/api_controller";
import { ProgressSpinner } from "primereact/progressspinner";
import AlphaJourneyWin from "./AlphaJourneyWin";
import AlphaJourneyAutomated from "./AlphaJourneyAutomated";
import AlphaJourneyEmpty from "./AlphaJourneyEmpty";

export default function ShowJourneyWin() {
    const [loadingStandard, setLoadingStandard] = useState(true);
    const [loadingDynamic, setLoadingDynamic] = useState(true);
    const [loadingAutomated, setLoadingAutomated] = useState(true);
    const [errorStandard, setErrorStandard] = useState(false);
    const [errorDynamic, setErrorDynamic] = useState(false);
    const [errorAutomated, setErrorAutomated] = useState(false);
    const [standardJourneys, setStandardJourneys] = useState([]);
    const [dynamicJourneys, setDynamicJourneys] = useState([]);
    const [AlphaJourneyAutomateds, setAlphaJourneyAutomateds] = useState([]);
    const [isActive, setIsActive] = useState(true); // Estado para alternar entre Ativos e Arquivados

    useEffect(() => {
        fetchJourneys();
    }, [isActive]);

    const fetchJourneys = () => {
        const activeParam = isActive ? "True" : "False";

        // Fetch Standard Journeys
        amc_api
            .get(`/alphaportifolio/get_win_journey_standard_details/?is_active=${activeParam}`)
            .then((res) => setStandardJourneys(res.data))
            .catch(() => setErrorStandard(true))
            .finally(() => setLoadingStandard(false));

        // Fetch Dynamic Journeys
        amc_api
            .get(`/alphaportifolio/get_win_journey_dynamic_details/?is_active=${activeParam}`)
            .then((res) => setDynamicJourneys(res.data))
            .catch(() => setErrorDynamic(true))
            .finally(() => setLoadingDynamic(false));

        // Fetch Automated Journeys
        amc_api
            .get(`/alphaportifolio/get_win_journey_automated_details/?is_active=${activeParam}`)
            .then((res) => setAlphaJourneyAutomateds(res.data))
            .catch(() => setErrorAutomated(true))
            .finally(() => setLoadingAutomated(false));
    };

    const renderJourneys = (journeys, Component) => {
        return journeys.length > 0 ? (
            journeys.map((journey, index) =>
                journey.metrics.total_operacoes > 0 ? (
                    <Component key={index} data={journey} />
                ) : (
                    <AlphaJourneyEmpty key={index} data={journey} />
                )
            )
        ) : (
            <p>Nenhuma jornada encontrada.</p>
        );
    };

    return (
        <div className="container">
            <div className="text-center mb-4">
                <div className="time-range grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                    <div
                        className={
                            "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                            (isActive
                                ? "text-gray-400 bg-bluegray-100"
                                : "")
                        }
                        onClick={() => setIsActive(true)}
                    >
                        Ativos
                    </div>
                    <div
                        className={
                            "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 ml-2 " +
                            (!isActive
                                ? "text-gray-400 bg-bluegray-100"
                                : "")
                        }
                        onClick={() => setIsActive(false)}
                    >
                        Arquivados
                    </div>
                </div>
            </div>

            <div className="standard-journeys">
                <h2 className="text-3xl pt-3 font-bold">Jornadas Standard do Mini Índice</h2>
                {loadingStandard && <ProgressSpinner />}
                {errorStandard && <p>Erro ao carregar as jornadas Standard</p>}
                {!loadingStandard && !errorStandard && renderJourneys(standardJourneys, AlphaJourneyWin)}
            </div>

            <div className="dynamic-journeys mt-5">
                <h2 className="text-3xl pt-3 font-bold">Jornadas Dynamic do Mini Índice</h2>
                {loadingDynamic && <ProgressSpinner />}
                {errorDynamic && <p>Erro ao carregar as jornadas Dynamic</p>}
                {!loadingDynamic && !errorDynamic && renderJourneys(dynamicJourneys, AlphaJourneyWin)}
            </div>

            <div className="automated-journeys mt-5">
                <h2 className="text-3xl pt-3 font-bold">Jornadas Automated do Mini Índice</h2>
                {loadingAutomated && <ProgressSpinner />}
                {errorAutomated && <p>Erro ao carregar as jornadas Automated</p>}
                {!loadingAutomated && !errorAutomated && renderJourneys(AlphaJourneyAutomateds, AlphaJourneyAutomated)}
            </div>
        </div>
    );
}
