import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";

import "primeicons/primeicons.css";

export default function FormField({ label, field, register, errors, options }) {
    const [selectGender, setSelectGender] = useState(null);
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);

    if (field === "email") {
        return (
            <div className="field">
                <label htmlFor={field}>{label}</label>
                <input
                    id={field}
                    name={field}
                    className="p-inputtext p-component w-full"
                    type="email"
                    {...register(field, options)}
                />
                {errors[field] && (
                    <small id="help">
                        <div className="text-orange-500">
                            {errors[field].message}
                        </div>
                    </small>
                )}
            </div>
        );
    }

    if (field === "phone_number") {
        return (
            <div className="field">
                <label htmlFor={field}>{label}</label>
                <InputMask
                    id={field}
                    className="w-full"
                    mask="+99 (99) 9 9999-9999"
                    placeholder="+55 (99) 9 9999-9999"
                    {...register(field, options)}
                />
                {errors[field] && (
                    <small id="help">
                        <div className="text-orange-500">
                            {errors[field].message}
                        </div>
                    </small>
                )}
            </div>
        );
    }

    if (field === "password") {
        return (
            <div className="field">
                <label htmlFor={field}>{label}</label>
                <div className="p-password p-component p-inputwrapper p-input-icon-right">
                    <input
                        id={field}
                        name={field}
                        className="p-inputtext p-component w-full"
                        type={showPassword ? "password" : "text"}
                        {...register(field, options)}
                    />
                    <i
                        id="togglePass"
                        className={
                            "pi " + (showPassword ? "pi-eye" : "pi-eye-slash")
                        }
                        onClick={() => setShowPassword(!showPassword)}
                    ></i>
                </div>
                {errors[field] && (
                    <small id="help">
                        <div className="text-orange-500">
                            {errors[field].message}
                        </div>
                    </small>
                )}
            </div>
        );
    }

    if (field === "passwordConfirm") {
        return (
            <div className="field">
                <label htmlFor={field}>{label}</label>
                <div className="p-password p-component p-inputwrapper p-input-icon-right">
                    <input
                        id={field}
                        name={field}
                        className="p-inputtext p-component w-full"
                        type={showConfirmPassword ? "password" : "text"}
                        {...register(field, options)}
                    />
                    <i
                        id="togglePass"
                        className={
                            "pi " +
                            (showConfirmPassword ? "pi-eye" : "pi-eye-slash")
                        }
                        onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                        }
                    ></i>
                </div>
                {errors[field] && (
                    <small id="help">
                        <div className="text-orange-500">
                            {errors[field].message}
                        </div>
                    </small>
                )}
            </div>
        );
    }

    if (field === "gender" || field === "investment_profile") {
        return (
            <div className="field">
                <label htmlFor={field}>{label}</label>
                <Dropdown
                    value={selectGender}
                    {...register(field)}
                    onChange={(e) => setSelectGender(e.value)}
                    optionLabel="label"
                    optionValue="value"
                    options={options}
                    placeholder="Escolha uma opção"
                    className="w-full"
                />
                {errors[field] && (
                    <small id="help">
                        <div className="text-orange-500">
                            {errors[field].message}
                        </div>
                    </small>
                )}
            </div>
        );
    }

    return (
        <div className="field">
            <label htmlFor={field}>{label}</label>
            <InputText
                id={field}
                {...register(field, options)}
                className="w-full"
            />
            <small id="help">
                {errors[field] && (
                    <div className="text-orange-500">
                        {errors[field].message}
                    </div>
                )}
            </small>
        </div>
    );
}
