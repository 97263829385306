import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";

import { Button } from "primereact/button";

import { amc_api } from "../../utils/api_controller";
import "./login.css";

export default function LoginForm() {
    const [loading, setLoading] = useState();
    const [errorMsg, setErrorMsg] = useState();
    const defaultValues = {
        username: "",
        password: "",
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues });

    function controlForm(data) {
        setLoading(true);
        amc_api
            .post("users/api_token_auth/", {
                username: data.username,
                password: data.password,
            })
            .then((res) => {
                localStorage.setItem("token", res.data.token);
                window.location.reload()
                // if (path === "/minha-conta") {
                // }
                // window.location.href = "/#" + path;
            })
            .catch((error) => {
                setErrorMsg(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function getFormErrorMessage(name) {
        return errors[name] ? (
            <small className="p-error">{errors[name].message}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    }

    return (
        <div className="loginForm">
            <form
                onSubmit={handleSubmit(controlForm)}
                className="loginForm flex flex-column flex justify-content-center"
            >
                <div className="text-2xl font-bold">Entrar</div>
                <Controller
                    name="username"
                    control={control}
                    rules={{ required: "Este campo não pode ficar vazio." }}
                    render={({ field, fieldState }) => (
                        <div className="d-flex flex-column gap-2 w-full pt-4 p1b-4">
                            <label
                                htmlFor={field.name}
                                className={classNames({
                                    "p-error": errors.value,
                                })}
                            ></label>
                            <span className="p-float-label">
                                <InputText
                                    id={field.name}
                                    value={field.value}
                                    className={
                                        "w-full " +
                                        classNames({
                                            "p-invalid": fieldState.error,
                                        })
                                    }
                                    onChange={(e) =>
                                        field.onChange(e.target.value)
                                    }
                                />
                                <label htmlFor={field.name}>
                                    Nome de usuário
                                </label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </div>
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Este campo não pode ficar vazio." }}
                    render={({ field, fieldState }) => (
                        <div className="d-flex flex-column pt-4 pb-4">
                            <label
                                htmlFor={field.name}
                                className={classNames({
                                    "p-error": errors.value,
                                })}
                            ></label>
                            <span className="p-float-label">
                                <Password
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) =>
                                        field.onChange(e.target.value)
                                    }
                                    toggleMask
                                    feedback={false}
                                />
                                <label htmlFor={field.name}>Senha</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </div>
                    )}
                />
                {errorMsg && (
                    <div className="mb-2">
                        <strong>Erro:</strong> {errorMsg}
                    </div>
                )}
                <Button
                    type="submit"
                    label={
                        loading ? (
                            <ProgressSpinner style={{ height: "18px" }} />
                        ) : (
                            "Entrar"
                        )
                    }
                    className={"mb-4 w-100"}
                    disabled={loading}
                ></Button>
            </form>
        </div>
    );
}
