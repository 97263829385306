import React, { useState, useEffect } from "react";

import { ProgressSpinner } from "primereact/progressspinner";

import AlphaBlock from "./AlphaBlock";
import { amc_api } from "../../utils/api_controller";

export default function FeaturedAlphas() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");

    useEffect(() => {
        amc_api
            .get(`alphaportifolio/get_alphas_highlights`)
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(setLoading(false));
    }, []);

    return (
        <>
            <div className="flex p-6 text-center justify-content-center">
                {loading && <ProgressSpinner />}
                {error && <>Erro ao carregar Alphas</>}
                {data && (
                    <div className="alpha-details">
                        <div className="text-center text-3xl font-bold">
                            Escolha seu Alpha e comece agora!
                        </div>
                        <div className="flex justify-content-center">
                            <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                                {data.produtos.map((produto, key) => (
                                    <AlphaBlock key={key} data={produto} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
