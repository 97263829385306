import React, { useState, useEffect } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";

import FaqCategory from "./FaqCategory";

import ReactGA from "react-ga4";

export default function FaqNew() {
    ReactGA.send({
        hitType: "pageview",
        page: "/faq",
        title: "FAQ / Sobre a AMC",
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    const getCategoriesUri =
        "https://tzv1a26gyc.execute-api.us-east-1.amazonaws.com/prod/faq/?action=get_categories";

    useEffect(() => {
        axios
            .get(getCategoriesUri)
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <div className="pt-6 container">
                <div className="text-900 font-bold text-5xl mb-3">
                    Perguntas Frequentes
                </div>
                <div className="flex flex-column">
                    {loading && <ProgressSpinner />}
                    {error && <>Erro ao carregar</>}
                    {data && (
                        <>
                            {data.map((faqCategory, key) => (
                                <FaqCategory key={key} faqCategory={faqCategory} />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
