import React from "react";
import { useLocation } from "react-router-dom";

import { checkAccess } from "../../utils/api_controller";
import MinhaConta from "../auth/Auth";
import CheckOut from "./CheckOut";
import ReactGA from "react-ga4";


export default function Cart() {
    ReactGA.send({
        hitType: "pageview",
        page: "/cart",
        title: "Carrinho",
    });

    let redirect = useLocation();
    const access = checkAccess();

    const purchaseDetails = localStorage.getItem("purchaseDetails")
        ? JSON.parse(localStorage.getItem("purchaseDetails"))
        : false;

    return (
        <div className="miniContainer p-3">
            {access ? <CheckOut purchaseDetails={purchaseDetails} /> : <MinhaConta path={redirect.pathname} />}
        </div>
    );
}
