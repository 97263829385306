import React from 'react';

import InstagramIcon from "../../assets/instagram-icon.png"; // Caminho para o ícone do Instagram
import FacebookIcon from "../../assets/facebook-icon.png"; // Caminho para o ícone do Facebook
import LinkedInIcon from "../../assets/linkedin-icon.png"; // Caminho para o ícone do LinkedIn
import LogoNvidia from "../../assets/NVIDIA.png"; // Caminho para a logo da NVIDIA

export default function Footer() {
  return (
    <div className="footer">
      <p><b>Alpha Monkey Club</b><br />Todos os direitos reservados - {new Date().getFullYear()} </p>
      <img src={LogoNvidia} alt="NVIDIA Logo" style={{ maxWidth: "200px", height: "auto" }} />

      <div className="social-links">
        <a href="https://www.facebook.com/profile.php?id=61558475882019" className="flex items-center gap-3" target="_blank" rel="noopener noreferrer">
          <img src={FacebookIcon} alt="Facebook" className="social-icon" />
        </a>

        <a href="https://www.instagram.com/alphamonkeyclub/" className="flex items-center gap-3" target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} alt="Instagram" className="social-icon" />
        </a>

        <a href="https://www.linkedin.com/company/alpha-monkey-club/" className="flex items-center gap-3" target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn" className="social-icon" />
        </a>
      </div>
    </div>
  );
}
