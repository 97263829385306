import React, { useState } from "react";
import LoginForm from "./Login";
import { Button } from "primereact/button";
import CreateAccountForm from "../account/CreateAccountForm";
import MyAccount from "../account/MyAccount2";
import { checkAccess } from "../../utils/api_controller";
import ReactGA from "react-ga4";

export default function Auth({ path }) {
    const [createAccount, setCreateAccount] = useState(false);
    const access = checkAccess();

    access
        ? ReactGA.send({
            hitType: "pageview",
            page: "/login",
            title: "Login",
        })
        : ReactGA.send({
            hitType: "pageview",
            page: "/minha-conta",
            title: "Conta logada",
        });

    return access ? (
        <MyAccount />
    ) : (
        <div className="miniContainer p-3">
            {createAccount ? <CreateAccountForm /> : <LoginForm />}
            <Button
                id="switchForm"
                type="submit"
                label={createAccount ? "Cancelar" : "Criar conta"}
                className={"mb-4 w-full bg-green-900"}
                onClick={() => setCreateAccount(!createAccount)}
            ></Button>
        </div>
    );
}
