import Home from "../components/Layout/home/Home";
import AlphaDetails from "../components/listAllAlphas/AlphaDetails";
import Cart from "../components/cart/Cart";
import SessionStatus from "../components/cart/SessionStatus";
import Auth from "../components/auth/Auth";
import TodosAlphas2 from "../components/Layout/TodosAlphas2";
import FaqNew from "../components/Layout/Faq/Faq";
import PrivacyPolicy from '../components/Layout/PrivacyPolicy';
import TermsOfUse from '../components/Layout/TermsOfUse';

export const menuLinks = [
    {
        title: "Início",
        path: "/",
        component: <Home />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Todos Alphas",
        path: "/todos-alphas",
        component: <TodosAlphas2 />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Sobre a AMC",
        path: "/faq",
        component: <FaqNew />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Detalhes do Alpha",
        path: "/alpha-details/:alphaId/:periodo",
        component: <AlphaDetails />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Detalhes do Alpha",
        path: "/todos-alphas/alpha-details/:alphaId/:periodo",
        component: <AlphaDetails />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Carrinho",
        path: "/cart",
        component: <Cart />,
        isUserOnly: true,
        isMenu: false,
    },
    {
        title: "Conta",
        path: "/minha-conta",
        component: <Auth path={'/minha-conta'} />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Compra Concluida",
        path: "/compra-concluida",
        component: <Auth path={'/minha-conta'} />,
        isUserOnly: true,
        isMenu: false,
    },
    {
        title: "Status da Compra",
        path: "/return",
        component: <SessionStatus />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Política de Privacidade",
        path: "/politica-de-privacidade",
        component: <PrivacyPolicy />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Termos de Uso",
        path: "/termos-de-uso",
        component: <TermsOfUse />,
        isUserOnly: false,
        isMenu: false,
    },
];
