import React, { useState, useEffect } from "react";
import $ from "jquery";
import { NavLink } from "react-router-dom";
import { amc_api } from "../../utils/api_controller"; // Importando o amc_api para fazer a chamada à API

import { menuLinks } from "../../navigation/MenuLinks";
import Logo from "../../assets/AMC-Logo-White.png";
import "./Header.css";

export default function Header() {
    const [userName, setUserName] = useState(null); // Estado para armazenar o nome do usuário

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await amc_api.get('/users/logged_user/');
                setUserName(response.data.first_name); // Armazena o primeiro nome do usuário
            } catch (error) {
                console.error("Erro ao buscar dados do usuário:", error);
                if (error.response && error.response.status === 404) {
                    // Se a resposta for 404, removemos o token da sessão
                    sessionStorage.removeItem('authToken');
                }
            }
        };

        fetchUserData();
    }, []); // Executa uma vez ao montar o componente

    return (
        <div className="navbar">
            <nav className="px-4 py-2 flex">
                <div className="mobileNav prsy-flex align-items-center">
                    <div
                        className="burger"
                        onClick={() => {
                            $("#burger.pi").toggleClass("pi-times pi-bars");
                            $(".mobileMenu").toggle();
                        }}
                    >
                        <i id="burger" className="pi pi-bars"></i>
                    </div>
                </div>
                <div className="logo flex justify-content-center">
                    <a href="/" className="navbrand">
                        <img src={Logo} alt="Alpha Monkey Club" />
                    </a>
                </div>
                <div className="nav prsy-flex flex-grow-1 align-items-center justify-content-end gap-5">
                    {menuLinks.map((item, key) =>
                        item.isMenu ? (
                            <NavLink
                                key={key}
                                className="nav-item"
                                to={item.path}
                            >
                                {item.title}
                            </NavLink>
                        ) : null
                    )}
                    <NavLink className="nav-item" to="minha-conta">
                        {userName ? `Olá, ${userName}` : "Minha conta"}
                    </NavLink>
                </div>
            </nav>
            <div className="mobileMenu gap-5 px-4">
                <div
                    className="prsy-flex flex-column"
                    onClick={() => {
                        $("#burger.pi").toggleClass("pi-times pi-bars");
                        $(".mobileMenu").toggle();
                    }}
                >
                    {menuLinks.map((item, key) =>
                        item.isMenu ? (
                            <NavLink
                                key={key}
                                className="nav-item py-2"
                                to={item.path}
                            >
                                {item.title}
                            </NavLink>
                        ) : null
                    )}
                    <NavLink className="nav-item py-2" to="minha-conta">
                        {userName ? `Olá, ${userName}` : "Minha conta"}
                    </NavLink>
                </div>
            </div>
        </div>
    );
}
