import React from "react";
import { Button } from "primereact/button";

import BG from "../../../assets/bg-slide.png";
import { useNavigate } from "react-router-dom";

export default function HomeBanner() {
    const navigate = useNavigate()
    return (
        <div className="grid grid-nogutter surface-800 text-800">
            <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                    <span className="block text-6xl text-0 font-bold mb-1">
                        Transforme dados em
                    </span>
                    <div className="text-6xl text-blue-500 font-bold mb-3">
                        decisões inteligentes
                    </div>
                    <p className="text-2xl mt-0 mb-4 text-0 line-height-3">
                        Abordagem científica com análise quantitativa para
                        previsões com alta acurácia no mercado de ações.
                    </p>

                    <Button
                        label="Assinar agora"
                        type="button"
                        className="mr-3 p-button-raised"
                        onClick={() => navigate("/todos-alphas")}
                    />
                </section>
            </div>
            <div className="col-12 md:col-6 overflow-hidden">
                <img
                    src={BG}
                    alt="hero-1"
                    height={"500"}
                    className="md:ml-auto block"
                    style={{
                        clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)",
                    }}
                />
            </div>
        </div>
    );
}
